import React from 'react';
import WebsiteMenu from './Website-Menu';
import WebsiteFooter from './Website-Footer';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import showError from './toast-message';
import BaseUrl from './BaseUrl';

export default function WebsiteProduts() {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${BaseUrl()}products`);
            setProducts(response.data);
        } catch (error) {
            console.error("Error fetching products:", error);
            showError("Failed to fetch products. Please try again later.");
        }
    };

    const DisplayProduct = function (item) {
        return (
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="course-item bg-light d-flex flex-column p-3">
                <div class="position-relative overflow-hidden">
                    <div class="ratio ratio-4x3">
                        <img src={`${BaseUrl()}images/${item.photo}`} class="img-fluid" alt="Course Image" />
                    </div>
                    <div class="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                        <a href="/About" class="btn btn-sm btn-primary px-4 border-end rounded-start">
                            About Us
                        </a>
                        <a href="/Contact" class="btn btn-sm btn-primary px-4 rounded-end">
                            Contact Us
                        </a>
                    </div>
                </div>
                <div class="text-center p-4 pb-2 mt-auto">
                    <h3 class="mb-0">${item.MSRP}</h3>
                    <div class="mb-3">
                        <i class="fa fa-star text-primary"></i>
                        <i class="fa fa-star text-primary"></i>
                        <i class="fa fa-star text-primary"></i>
                        <i class="fa fa-star text-primary"></i>
                        <i class="fa fa-star text-primary"></i>
                    </div>
                    <h5 class="mb-4">${item.title}</h5>
                </div>
            </div>
        </div>
        
        
        );
    }

    return (
        <>
            <WebsiteMenu />
            <div className="container-fluid bg-primary py-5 mb-5 page-header">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 text-center">
                            <h1 className="display-3 text-white animated slideInDown">Products</h1>
                            <nav aria-label="">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="">
                                        <a className="text-white text-decoration-none" href="/">
                                            Home/
                                        </a>
                                    </li>
                                    <li className="">
                                        <a className="text-white text-decoration-none " href="/">
                                            Pages/
                                        </a>
                                    </li>
                                    <li
                                        className=" text-white active"
                                        aria-current="page"
                                    >
                                        Products
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row g-4 justify-content-center">
            {products.map((item) => DisplayProduct(item))}
            </div>


            <WebsiteFooter />

            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
                <i className="bi bi-arrow-up" />
            </a>





        </>

    );
}
